import { AfterViewInit, Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { ScrollService } from '../../../shared/services/scroll.service';
import { Button } from '../../../shared/interfaces';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../../shared/enums';
import { environment } from '../../../../environments/environment';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { CommonModule } from '@angular/common';
import { HeaderProfileComponent } from 'src/app/shared/components/header-profile/header-profile.component';
import { EmailConfirmationComponent } from 'src/app/shared/components/email-confirmation/email-confirmation.component';
import { LazyLoadDirective } from 'src/app/shared/directives/lazy-load.directive';
import { fadeInOutAnimation } from 'src/app/shared/animations/fadeInOut.animation';
import { AuthStateService } from 'src/app/shared/services/auth/auth-state.service';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
  imports: [ButtonComponent, CommonModule, HeaderProfileComponent, EmailConfirmationComponent, LazyLoadDirective],
  standalone: true,
  animations: [fadeInOutAnimation]
})
export class HomeHeaderComponent implements AfterViewInit {
  isSideBarOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentRoute: string;

  loaded = false;

  readonly signInBtn: Button = {
    name: 'Sign in',
    size: BUTTON_SIZE.MD,
    type: BUTTON_TYPE.DARK
  };

  readonly memberBtn: Button = {
    name: 'Become a member',
    size: BUTTON_SIZE.MD,
    type: BUTTON_TYPE.LIGHT
  };

  isLoggedIn$ = this.authStateService.isLoggedIn$;

  constructor(
    private router: Router,
    public authService: AuthService,
    private authStateService: AuthStateService,
    private scrollService: ScrollService
  ) {
    this.currentRoute = this.router.url;
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.currentRoute = route.url;
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loaded = true;
    }, 0);
  }

  signIn() {
    if (!this.authStateService.isLoggedIn) {
      this.authService.login();
    } else {
      this.router.navigateByUrl('content');
    }
  }

  goToPackages() {
    this.closeSidebar();
    this.scrollService.scrollTo('packagesSection');
    this.navigateToHome();
  }

  becomeMember() {
    this.authService.login();
  }

  goToFaqs() {
    this.closeSidebar();
    this.scrollService.scrollTo('faqsSection');
    this.navigateToHome();
  }

  navigateToHome() {
    if (this.currentRoute !== '/' && this.currentRoute !== '/signin' && this.currentRoute !== '/signup') {
      this.router.navigateByUrl('/');
    }
  }

  goToBlog() {
    this.closeSidebar();
    window.open(environment.gaiminGladiatorsBlogLink, '_blank');
  }

  openSidebar() {
    this.isSideBarOpen$.next(true);
  }

  closeSidebar() {
    this.isSideBarOpen$.next(false);
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  openRoot() {
    this.router.navigateByUrl('/');
  }
}
