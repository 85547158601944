<div class="login modal" [ngClass]="{ 'full-screen': data.isFullScreen }">
  <div class="modal-content">
    <span *ngIf="!data.isFullScreen" class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
    <div class="login__logo" *ngIf="data.isFullScreen">
      <img src="assets/gladiators-logo.svg" alt="Gaimin Gladiators" lazyLoad />
    </div>
    <div class="login__title">
      <h2>Sign in</h2>
      <h3>
        <!-- {{ isRegister ? 'Already have an account?' : 'Do not have an account?' }} -->
        <!-- <span (click)="toggleFormType()">{{ isRegister ? 'Sign in' : 'Sign up' }}</span> -->
      </h3>
    </div>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <label class="login__label" for="email">
        Email
        <input formControlName="email" class="login__field" type="text" placeholder="" id="email" />

        <div>
          <ng-container *ngIf="loginForm.get('email')!.invalid && loginForm.get('email')!.dirty">
            <span *ngIf="loginForm.get('email')!.hasError('required')" class="login__field__error">
              Email should be present
            </span>
            <span *ngIf="loginForm.get('email')!.hasError('email')" class="login__field__error">
              Valid email address required
            </span>
          </ng-container>
        </div>
      </label>

      <label class="login__label" for="password">
        Password

        <password-input formControlName="password" [id]="'password'"></password-input>

        <div>
          <span
            *ngIf="loginForm.get('password')!.hasError('required') && loginForm.get('password')!.dirty"
            class="login__field__error">
            Password should be present
          </span>
        </div>
      </label>

      <app-button
        [attr.disabled]="loginForm.invalid"
        [buttonData]="{ name: 'Sign in', size: BUTTON_SIZE.LG }"></app-button>
    </form>

    <div class="login__divider"><span>OR</span></div>

    <app-socials-login ></app-socials-login>

    <span  class="login__link">
      <a href="https://okta.gaimin.net/signin/forgot-password">Forgot password?</a>
    </span>
  </div>
</div>
