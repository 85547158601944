import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { BUTTON_TYPE } from 'src/app/shared/enums';

@Component({
  selector: 'cancel-migration-modal',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './cancel-migration-modal.component.html',
  styleUrl: './cancel-migration-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelMigrationModalComponent {
  BUTTON_TYPE = BUTTON_TYPE;
  constructor(
    private dialogRef: MatDialogRef<CancelMigrationModalComponent>,
    private authService: AuthService
  ) {}

  confirm() {
    this.authService.cancelMigration();
    this.dialogRef.close({ confirm: true });
  }

  back() {
    this.dialogRef.close();
  }
}
