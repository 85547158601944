<div class="modal">
  <div class="header">
    <h2>Action Required: Complete Your GAIMIN Account Migration by {{ date }}</h2>
    <span class="icon-Close close-icon" title="Close" (click)="requestClose()"></span>
  </div>

  <div class="modal-content">
    <h3>PLEASE READ - VERY IMPORTANT ACCOUNT UPGRADE</h3>
    <p>
      You are seeing this notice because we identified your email in our old GAIMIN database, and noticed your account
      still needs to be migrated to our new Single Sign-On (SSO) system. You missed the active migration notice but can
      still complete your migration now.
    </p>

    <h3>Why You Need to Act:</h3>
    <ul>
      <li>
        <p>Access to your old GAIMIN account will be restricted after January 12, 2025.</p>
      </li>
      <li>
        <p>After this restriction, you’d have to contact our tech support to manually migrate your account.</p>
      </li>
    </ul>
    <p><b>Note:</b> Manual migration after the deadline is slower and risks potential assets loss!</p>

    <h3>What You Need to Do:</h3>
    <ol>
      <li>
        <p>Click “Complete Migration Now” below.</p>
      </li>
      <li>
        <p>
          ‘Re-signup’ on GAIMIN using the same old email, to ensure your old account details and data are linked to the
          new one.
        </p>
      </li>
      <li>
        <p>
          Set up your wallet’s recovery password—store it somewhere securely, as GAIMIN cannot recover this for you if
          lost.
        </p>
      </li>
    </ol>
    <p>
      This migration enhances security and unlocks streamlined access to all exciting GAIMIN products and services from
      one account.
    </p>
    <p>
      For any questions, contact our support team at:
      <a href="mailto:technical_support@gaimin.io">technical_support&#64;gaimin.io</a>
    </p>

    <p>
      <b>Don’t delay — secure your account now!</b>
    </p>
    <app-button (click)="submit()" [buttonData]="confirmButton"></app-button>
  </div>
</div>
