import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  LoginResponse,
  UserSignInCredentials,
  Response,
  SubscriptionTypeInfo,
  ChangePasswordRequest,
  DpaEventEnrollmentData,
  UtmSourceRequest,
  UtmSourceResponse,
  UserInfo
} from '../../interfaces';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AuthStateService } from './auth-state.service';
import { COLOSSEUM_TIERS, USER_ROLES } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class AuthRequestService {
  refreshEndpoint = `/auth/token/refresh`;

  constructor(
    private readonly http: HttpClient,
    private readonly authStateService: AuthStateService
  ) {}

  oktaLoginRequest(userData: UserSignInCredentials): Observable<Response<LoginResponse>> {
    return this.http.post<Response<LoginResponse>>(`${environment.gaiminApi}/users/auth`, {
      ...userData,
      fromColosseum: true
    });
  }

  checkEmptyProfileInfoRequest(): Observable<Response<boolean>> {
    return this.http.get<Response<boolean>>(environment.gaiminApi + '/users/me/empty-profile-info');
  }

  getUserSubscriptionTypeRequest(): Observable<Response<SubscriptionTypeInfo>> {
    return this.http.get<any>(environment.gaiminApi + '/colosseum-subscription/active');
  }

  acquireUserSubscriptionRequest(tier: COLOSSEUM_TIERS): Observable<Response<SubscriptionTypeInfo>> {
    return this.http.post<Response<SubscriptionTypeInfo>>(
      `${environment.gaiminApi}/colosseum-subscription/acquire?tier=${tier}`,
      {}
    );
  }

  refreshTokenRequest(refreshToken: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.authModuleApi}${this.refreshEndpoint}`,
      {
        refreshToken
      },
      {
        headers: new HttpHeaders().set('skipAuth', 'true')
      }
    );
  }

  changePasswordRequest(newUserData: ChangePasswordRequest): Observable<Response<any>> {
    return this.http.post<Response<any>>(`${environment.gaiminApi}/users/me/change-password`, newUserData);
  }

  dpaEventEnrollmentRequest(): Observable<Response<DpaEventEnrollmentData>> {
    return this.http.get<Response<DpaEventEnrollmentData>>(`${environment.gaiminApi}/dpa-event/user-enrollment`);
  }

  utmSourceIdRequest(dto: UtmSourceRequest): Observable<Response<UtmSourceResponse>> {
    return this.http.post<Response<UtmSourceResponse>>(`${environment.gaiminApi}/users/utm`, dto);
  }

  getUserPermissionsRequest(encodedCredentials: string) {
    const headers = new HttpHeaders().set('Authorization', `Basic ${encodedCredentials}`).set('skipAuth', 'true');
    return this.http.get<Response<USER_ROLES[]>>(`${environment.gaiminApi}/admin/roles`, {
      headers
    });
  }

  addToMailingList() {
    return this.http.post<Response<any>>(`${environment.gaiminApi}/users/mailing-list`, null);
  }

  checkMigrationStatus(unverifiedAccessToken: string) {
    return this.http.post<Response<'ALREADY_MIGRATED' | 'NOT_MIGRATED' | 'NEW'>>(
      `${environment.gaiminApi}/users/auth/migration_state`,
      {
        gaiminToken: `Bearer ${unverifiedAccessToken}`
      }
    );
  }

  createPlatformUser(unverifiedAccessToken: string) {
    return this.http.post<Response<string>>(`${environment.gaiminApi}/users/auth/create`, {
      gaiminToken: `Bearer ${unverifiedAccessToken}`
    });
  }

  migratePlatformUser(unverifiedAccessToken: string, oktaToken: string) {
    return this.http.post<Response<UserInfo>>(`${environment.gaiminApi}/users/auth/create`, {
      gaiminToken: `Bearer ${unverifiedAccessToken}`,
      oktaToken: `${oktaToken}`
    });
  }
}
