import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-socials-login',
  templateUrl: './socials-login.component.html',
  styleUrls: ['./socials-login.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class SocialsLoginComponent {
  constructor(private authService: AuthService) {}

  login() {
    this.authService.socialLoginWithOkta();
  }
}
