import { Component, Inject } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { AdditionalService } from '../../../services/additional.service';
import { BUTTON_SIZE } from '../../../enums';
import { ButtonComponent } from '../../button/button.component';
import { SocialsLoginComponent } from '../../socials-login/socials-login.component';
import { LazyLoadDirective } from 'src/app/shared/directives/lazy-load.directive';
import { PasswordInputComponent } from '../../password-input/password-input.component';
import { RouterModule } from '@angular/router';
import { AuthStateService } from 'src/app/shared/services/auth/auth-state.service';

@Component({
  selector: 'login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    SocialsLoginComponent,
    LazyLoadDirective,
    PasswordInputComponent,
    MatCheckboxModule,
    RouterModule
  ],
  standalone: true
})
export class LoginModalComponent {
  private readonly EMAIL_REGEX: string = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}';
  readonly BUTTON_SIZE: typeof BUTTON_SIZE = BUTTON_SIZE;
  emailMinLength = 3;
  emailMaxLength = 500;
  passwordMinLength = 8;
  passwordMaxLength = 50;
  nameMinLength = 2;
  nameMaxLength = 50;
  shouldRedirect: boolean = !this.data.isFullScreen;

  loginForm: FormGroup = new FormGroup<any>({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  registerForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.minLength(this.emailMinLength),
      Validators.maxLength(this.emailMaxLength),
      Validators.pattern(this.EMAIL_REGEX)
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(this.passwordMinLength),
      Validators.maxLength(this.passwordMaxLength),
      Validators.pattern('^(?!\\s)(?!.*\\s$)(?!.*?(\\s)).*$'),
      this.additionalService.regexValidator(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\\W]).*$'), {
        complexity: true
      })
    ]),
    firstName: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z- ]*$'),
      this.additionalService.regexValidatorV2(/\s{2,}/, { complexity: true })
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.minLength(this.nameMinLength),
      Validators.maxLength(this.nameMaxLength),
      Validators.pattern('^[a-zA-Z- ]*$'),
      this.additionalService.regexValidatorV2(/\s{2,}/, { complexity: true })
    ]),
    tos: new FormControl(false, [Validators.requiredTrue]),
    privacyPolicy: new FormControl(false, [Validators.requiredTrue]),
    mailingList: new FormControl(false, [])
  });

  constructor(
    private location: Location,
    private dialogRef: MatDialogRef<LoginModalComponent>,
    private authStateService: AuthStateService,
    public additionalService: AdditionalService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA)
    public data: { isSignUp: boolean; isFullScreen: boolean }
  ) {}

  performLoginRequest() {
    if (this.loginForm.invalid) return;

    this.authService.performOktaLoginRequest(this.loginForm.value).subscribe(() => {
      this.closeModal();
    });
  }

  onSubmit() {
    this.performLoginRequest();
  }

  closeModal() {
    if (!this.data.isFullScreen) {
      this.location.replaceState('/');
    }
    this.dialogRef.close();
  }
}
