import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { WalletConnectService } from 'src/app/shared/services/wallet-connect.service';
import { environment } from 'src/environments/environment';
import { AuthStateService } from 'src/app/shared/services/auth/auth-state.service';
import { AuthRequestService } from 'src/app/shared/services/auth/auth-request.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<string | null>(null);

  private readonly DESCRIPTION_EXCLUSION_LIST: string[] = [
    'Username is not unique',
    'Claim already submitted',
    'Event not found'
  ];

  constructor(
    private readonly authService: AuthService,
    private readonly authStateService: AuthStateService,
    private readonly authRequestService: AuthRequestService,
    private readonly walletConnectService: WalletConnectService,
    private readonly toastrService: ToastrService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      switchMap((event: HttpEvent<any>) => {
        if (!(event instanceof HttpResponse) || event.body == null || event.body.success) {
          return [event];
        }

        const errorType = event.body.error?.type;
        const errorDescription = event.body.error?.description;

        if (event.url?.includes('auth/migration_state') || event.url?.includes('auth/create')) {
          if (errorType === 'VALIDATION' && errorDescription.includes('JWT') && errorDescription.includes('expired')) {
            return this.handleAuthenticationError(request, next);
          }
          this.toastrService.error(
            'There was a problem with account authentication, please try again. If the problem continues, please get in touch with Technical Support technical_support@gaimin.io',
            'Authentication failed',
            {
              disableTimeOut: true
            }
          );
          this.authService.logout();
          return [event];
        }

        if (errorDescription != null && errorType !== 'AUTHENTICATION') {
          if (!this.DESCRIPTION_EXCLUSION_LIST.includes(errorDescription)) {
            const parseError = errorDescription.split('\n');
            if (parseError[1]) {
              this.toastrService.error(parseError[1], parseError[0]);
              return [event];
            }
            this.toastrService.error(parseError[0]);
          }
        }

        if (
          event.url?.includes(environment.gaiminApi + this.authRequestService.refreshEndpoint) &&
          errorType === 'AUTHORIZATION'
        ) {
          this.logoutProcess();
          return [event];
        }

        if (errorType === 'AUTHENTICATION' && !request.url.includes('roles')) {
          return this.handleAuthenticationError(request, next);
        }

        return [event];
      }),
      catchError((error) => {
        return throwError(() => {
          this.toastrService.error(error.message);
        });
      })
    );
  }

  private handleAuthenticationError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.authStateService.refreshTokenSnapshot;
      if (refreshToken) {
        return this.authService.performRefreshTokenRequest(refreshToken).pipe(
          switchMap((response) => {
            if (response.data) {
              this.refreshTokenSubject.next(response.data.accessToken);
              this.isRefreshing = false;
              return next.handle(request);
            } else {
              this.authService.logout();
              return throwError(() => new HttpErrorResponse({ status: 401 }));
            }
          }),
          catchError((error) => {
            this.isRefreshing = false;
            this.authService.logout();
            return throwError(() => error);
          })
        );
      } else {
        this.authService.logout();
        return throwError(() => new HttpErrorResponse({ status: 401 }));
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => next.handle(request))
      );
    }
  }

  private logoutProcess() {
    this.walletConnectService.disconnectFromWallet();
    this.authService.logout();
  }
}
