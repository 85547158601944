

<div class="modal">
  <div class="header">
    <h2>Are you sure you want to cancel the migration process?</h2>
    <span class="icon-Close close-icon" title="Close" (click)="back()"></span>
  </div>

  <div class="modal-content">
    <p>Once you close this window, you will need to contact support to migrate your GAIMIN account.</p>
    <p>Failure to do so may result in potential assets loss!</p>
    <div class="modal-content__buttons-list">
      <app-button class="dialog__button" [buttonData]="{ name: 'No', type: BUTTON_TYPE.GREY }" (click)="back()"></app-button>
      <app-button class="dialog__button" [buttonData]="{ name: 'Yes' }" (click)="confirm()"></app-button>
    </div>
  </div>
</div>
