import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent } from '../../button/button.component';
import { UserService } from '../../../services/user.service';
import { UserInfo, Button } from '../../../interfaces';
import { BUTTON_SIZE } from '../../../enums';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { CancelMigrationModalComponent } from '../cancel-migration-modal/cancel-migration-modal.component';
import { filter, take } from 'rxjs';

@Component({
  selector: 'app-update-gaimin-account-modal',
  templateUrl: './update-gaimin-account-modal.component.html',
  styleUrl: './update-gaimin-account-modal.component.scss',
  imports: [CommonModule, ButtonComponent],
  standalone: true
})
export class UpdateGaiminAccountModalComponent {
  confirmButton: Button = { name: 'Complete Migration Now', size: BUTTON_SIZE.MD };
  profile: UserInfo = {} as UserInfo;
  date: string = '12th January 2025';

  constructor(
    public userService: UserService,
    private dialogRef: MatDialogRef<UpdateGaiminAccountModalComponent>,
    private authService: AuthService,
    private dialogService: MatDialog
  ) {
    this.profile = this.userService.userInfo$.getValue();
    this.authService.migrationSuccess$
      .pipe(
        filter((success) => success),
        take(1)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  submit() {
    this.authService.openOktaLoginPopup();
  }

  requestClose() {
    const dialog = this.dialogService.open(CancelMigrationModalComponent, {
      panelClass: ['dialog-overlay-pane'],
      disableClose: true
    });

    dialog.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        this.dialogRef.close();
      }
    });
  }
}
